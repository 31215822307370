import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/src/templates/MDXDocs.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "optional-settings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#optional-settings",
        "aria-label": "optional settings permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Optional settings`}</h1>
    <h3 {...{
      "id": "set-a-view-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#set-a-view-type",
        "aria-label": "set a view type permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Set a view type`}</h3>
    <p>{`The provider submission and response user interface can be rendered as a `}<strong parentName="p">{`lightbox modal`}</strong>{` or an `}<strong parentName="p">{`embedded frame`}</strong>{`. It entirely up to you.`}</p>
    <p><a parentName="p" {...{
        "href": "/sdk/transaction/view-types/"
      }}>{`Click here to read more on `}<strong parentName="a">{`"view types"`}</strong></a></p>
    <h3 {...{
      "id": "hide-the-medipass-chat-bubble",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hide-the-medipass-chat-bubble",
        "aria-label": "hide the medipass chat bubble permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hide the Medipass chat bubble`}</h3>
    <p>{`To disable the Medipass live chat / help module in the provider interface. Set `}<inlineCode parentName="p">{`hideChatBubble`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` in the SDK options:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`sdk.renderCreateTransaction({
  // your payload
}, {
  hideChatBubble: true
})
`}</code></pre>
    <h3 {...{
      "id": "disable-modification-of-service-items",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#disable-modification-of-service-items",
        "aria-label": "disable modification of service items permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Disable modification of service items`}</h3>
    <p>{`To prevent providers from changing item details in the Medipass interface on error, set `}<inlineCode parentName="p">{`disableModifyServiceItems`}</inlineCode>{` to `}<inlineCode parentName="p">{`true`}</inlineCode>{` in the SDK options.`}</p>
    <p>{`This option is used by some practice management systems to maintain referential integrity on problematic transactions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`sdk.renderCreateTransaction({
  // your payload
}, {
  disableModifyServiceItems: true
})
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      